import { memo } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import useHasMounted from '@utils/hooks/useHasMounted';
import { useBFContext } from '@context';
import { BUILD_FLOW_STEPS } from '@constants';
import styles from './BuildFlow.module.scss';

const InteractivePane = dynamic(() => import('./InteractivePane/InteractivePane'));
const ViewPane = dynamic(() => import('./ViewPane/ViewPane'));

export type BuildFlowProps = {
	toggleBuildFlow: ({ type }) => void;
	journey?: string;
	jumpToStep?: boolean;
};

const BuildFlow = () => {
	const isBF2AllTops = useFeatureIsOn('bf-2-all-tops');
	const isFilterOutline = useFeatureIsOn('is-filter-outline-button');
	const isAlwaysOpen = useFeatureIsOn('is-always-filter-tops');

	const hasMounted = useHasMounted();
	const { step, isSubscriptionActivated } = useBFContext();
	const topFrameStep =
		step === BUILD_FLOW_STEPS.TOP_FRAMES || (!(isBF2AllTops && !isSubscriptionActivated) && step === BUILD_FLOW_STEPS.SUBMIT);

	if (!hasMounted) return;

	return (
		<div
			className={cn(styles.container, {
				[styles['bf-all-tops-container']]: topFrameStep,
				[styles['container--is-filter']]: isFilterOutline || isAlwaysOpen,
			})}
			data-step={step}
		>
			<ViewPane />
			<InteractivePane />
		</div>
	);
};

BuildFlow.displayName = 'BuildFlow';

export default memo(BuildFlow);
