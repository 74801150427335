import React, { memo } from 'react';
import { Heading, Paragraph } from '@components';
import Img from '../Img';
import styles from './PictureGrid.module.scss';
import { useTranslation } from '@utils/index';

type PictureGridProps = {
	caption?: string;
	src?: string;
	title?: string;
};

const getTranslatedTexts = translator => {
	return {
		ourLensesDescription: translator('our-lenses-description'),
		upgradeYourLenses: translator('upgrade-your-lenses'),
	};
};

const PictureGrid = ({
	caption,
	src = 'https://cdn.shopify.com/s/files/1/1147/9910/files/lens-upgrade-picture-grid.gif?v=1657120807',
	title,
}: PictureGridProps) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const defaultCaption = caption ?? translations.ourLensesDescription;
	const defaultTitle = title ?? translations.upgradeYourLenses;

	return (
		<section className={styles.container} data-section-name='picture-grid'>
			<div className={styles['text-container']}>
				<Heading tag='h2'>{defaultTitle}</Heading>
				<Paragraph style={{ maxWidth: '42rem' }}>{defaultCaption}</Paragraph>
			</div>
			<div className={styles['image-container']}>
				<Img className={styles.image} src={src} noSrcset alt={defaultTitle} sizes='100vw' />
			</div>
		</section>
	);
};

export default memo(PictureGrid);
