import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { ErrorBoundary, PictureGrid, ProductView } from '@components';
import { CUSTOMER_JOURNEYS } from '@constants';
import { useBFContext } from '@context';
import { normalizeProductType } from '@utils/normalizers';
import { useTranslation } from '@utils/index';

const FitDetails = dynamic(() => import('@components').then(mod => mod.FitDetails));
const HorizontalFrameSizing = dynamic(() => import('@components').then(mod => mod.HorizontalFrameSizing));
const Compare = dynamic(() => import('@components').then(mod => mod.Compare));
const AboutValueProp = dynamic(() => import('@components').then(mod => mod.AboutValueProp));
const InfoModule = dynamic(() => import('@components').then(mod => mod.InfoModule));

type BaseFramePDPDetailsProps = {
	journey: string;
	selectedMaterial: string;
	padding?: 'small' | 'medium' | 'large';
};

const getTranslatedTexts = translator => {
	return {
		baseFramesBenefits: translator('base-frames-benefits'),
	};
};

const BaseFramePDPDetails = ({ journey, selectedMaterial, padding = 'medium' }: BaseFramePDPDetailsProps) => {
	const { product, frameColor } = useBFContext();
	const [activeAccordion, setActiveAccordion] = useState(0);
	const {
		description,
		handle,
		name,
		type,
		metafields: { frameSizings },
	} = product;
	const normalizedType = normalizeProductType(type);
	const SUNGLASSES_JOURNEY = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const isMixedMaterial = selectedMaterial.includes('Mixed Material');
	const tags = product.variants.find(variant => variant.option === frameColor)?.tags;
	const handleAccordionClick = index => {
		setActiveAccordion(prevIndex => (prevIndex === index ? null : index));
	};
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<ProductView.Details padding={padding}>
			<ErrorBoundary>
				<FitDetails
					description={description}
					name={name}
					tags={tags}
					handle={handle}
					frameSizings={frameSizings}
					isOpen={activeAccordion === 0}
					onClick={() => handleAccordionClick(0)}
					isMixedMaterial={isMixedMaterial}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<HorizontalFrameSizing
					frameSizings={frameSizings}
					name={name}
					isOpen={activeAccordion === 1}
					onClick={() => handleAccordionClick(1)}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<Compare
					color={frameColor}
					product={product}
					isOpen={activeAccordion === 2}
					onClick={() => handleAccordionClick(2)}
				/>
			</ErrorBoundary>
			{SUNGLASSES_JOURNEY && <PictureGrid />}
			<AboutValueProp
				heading={translations.baseFramesBenefits}
				type={isMixedMaterial ? 'METAL_TEMPLE' : normalizedType}
				withOutMinWidth={padding == 'small'}
			/>
			<InfoModule journey={journey} />
		</ProductView.Details>
	);
};

export default BaseFramePDPDetails;
