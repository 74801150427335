import { useState, useRef, useMemo } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import cn from 'classnames';
import { FRAME_COLORS, NEW_COLORS, PRODUCT_TYPES } from '@constants';
import { Chevron, Flex, VerticalCard } from '@components';
import { Heading } from '@components';
import { NormalizedVariant } from '@ts/product';
import 'swiper/css';
import { useBaseFrameProducts } from '@services/shopify';
import styles from './RecommendationCarousel.module.scss';
import { useTranslation } from '@utils/index';

type RecommendationCarouselProps = {
	variant: NormalizedVariant;
	selectedFrame: string;
	headingText?: string;
	productTypes?: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES][];
	demo?: string;
	dataTags?: Record<
		'button' | 'zoom' | 'favorite',
		{
			[key: string]: string | boolean;
		}
	>;
	withPadding?: boolean;
};

const getTranslatedTexts = translator => {
	return {
		exploreProduct: (productName: string) => translator('explore-product', { productName }),
	};
};

/**
 * This is the same as the RecommendationCarousel component, but it always shows the same recommendations –– a list of Base Frames.
 * This is used on the Base Frame PDP since Algolia rarely recommends more Base Frame products for a given Base Frame.
 */
const FakeRecommendationCarousel = ({ variant, headingText, dataTags, withPadding = false }: RecommendationCarouselProps) => {
	const [isBeginning, setIsBeginning] = useState(false);
	const [isEnd, setIsEnd] = useState(false);

	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);

	const navigationRefs = {
		prevEl: navigationPrevRef.current,
		nextEl: navigationNextRef.current,
	};

	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const { data: recommendations } = useBaseFrameProducts();

	const mappedRecommendations = useMemo(() => {
		return (
			variant &&
			recommendations?.map(product => {
				if (!product || product.name === 'PAIRCare') return;

				const selectedVariant = product.variants[0];

				if (!selectedVariant?.availableForSale) return;

				if (product.type.includes(PRODUCT_TYPES.BASE_FRAME) && product.name === variant.name) {
					return null;
				}

				return (
					<SwiperSlide key={`recommendation-${product.id}-${product.name}`} className={styles.collectionSlider}>
						<VerticalCard
							primaryAction='view'
							product={product}
							showVariantControls
							variant={selectedVariant}
							label={translations.exploreProduct(product.name)}
							secondaryAction='none'
							dataTags={dataTags}
						/>
					</SwiperSlide>
				);
			})
		);
	}, [recommendations]);

	return (
		<div
			className={cn(styles.container, { [styles['container--withPadding']]: withPadding })}
			data-recommendation-carousel={variant?.type}
		>
			<Heading tag='h4' className={styles.heading}>
				{headingText}
			</Heading>
			<div className={styles.collectionTrack}>
				<Swiper
					autoplay={false}
					direction='horizontal'
					modules={[Navigation]}
					navigation={navigationRefs}
					onSlidesGridLengthChange={swiper => {
						setIsBeginning(swiper.isBeginning);
						setIsEnd(swiper.isEnd);
						swiper.on('reachEnd', () => {
							setIsEnd(true);
						});
					}}
					onSlideChange={swiper => {
						setIsBeginning(swiper.isBeginning);
						setIsEnd(swiper.isEnd);
					}}
					slidesPerView='auto'
					spaceBetween={16}
				>
					<ul>{mappedRecommendations}</ul>
					<Flex fullWidth justify='end' gap={3} className={styles['navigation-container']}>
						<div className={`${styles.prevButton} ${isBeginning ? styles.disabled : ''}`} ref={navigationPrevRef}>
							<Chevron direction='left' />
						</div>
						<div className={`${styles.nextButton} ${isEnd ? styles.disabled : ''}`} ref={navigationNextRef}>
							<Chevron direction='right' />
						</div>
					</Flex>
				</Swiper>
			</div>
		</div>
	);
};

export default FakeRecommendationCarousel;
